#home {
    background-color: black;

    #myVideo {
        min-width: 100%;
        min-height: 100vh;
        object-fit: cover;
    }

    .header-section {
        min-height: 100vh;
    }

    .brandsBg {
        background-image: url(../img/home/brandsBg.png);
        background-size: 100% 100%;
        background-position: top;
        background-repeat: no-repeat;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .headerPhoneSize {
        width: 90%;
        position: sticky;
        z-index: 10;
    }

    .applicationBtn {
        background: #A18C68;
        border-radius: 50px;
    }

    .applicationBtnText {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 41px;
        text-align: center;
        color: #000000;

        @media (max-width: 677px) {
            font-size: 16px;
            line-height: 25px;
        }
    }

    .our-brands {
        min-height: auto;

        @media (max-width: 992px) {
            min-height: auto;
        }

        @media (max-width: 677px) {
            min-height: auto;
        }
    }

    .headerTitle {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 70px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 40px;
            line-height: 45px;
        }

        @media (max-width: 677px) {
            font-size: 25px;
            line-height: 30px;
        }
    }

    .gameGroupTitle {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 82px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 40px;
            line-height: 45px;
        }

        @media (max-width: 677px) {
            font-size: 25px;
            line-height: 30px;
        }
    }

    .brandsDesc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 20px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 15px;
            line-height: 22px;
        }
    }

    .gamingGroupDesc {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 992px) {
            font-size: 20px;
            line-height: 30px;
        }

        @media (max-width: 677px) {
            font-size: 15px;
            line-height: 25px;
        }
    }

    .brandsLogo {
        width: 50%;
        aspect-ratio: 2/1;
        object-fit: contain;

        @media (max-width: 992px) {
            width: 70%;
        }

        @media (max-width: 677px) {
            width: 100%;
        }
    }

    .sideItem1Pos {
        position: absolute;
        top: 20%;
        left: 0;
        z-index: 0;

        img {
            @media (max-width: 992px) {
                // font-size: 30px;
                width: 200px;
            }

            @media (max-width: 677px) {
                width: 100px;
            }
        }
    }

    .sideItem2Pos {
        position: absolute;
        bottom: -30%;
        right: 0;
        z-index: 0;

        img {
            @media (max-width: 992px) {
                // font-size: 30px;
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .inactiveMobileSlide {
        text-align: center;
        position: relative;

        img {
            transform: scale(0.8);
        }
    }

    .activeMobileSlide {
        text-align: center;
        position: relative;

        img {
            transform: scale(1);
        }
    }

    .sliderArrowPrev {
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 10000;
    }

    .sliderArrowNext {
        position: absolute;
        right: 0;
        top: 50%;
    }

    .inactiveFadeRight {
        background: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 95.76%);
    }

    .game-category {
        min-height: 100vh;

        @media (max-width: 992px) {
            min-height: auto;
            padding-bottom: 10vh;
        }

        @media (max-width: 677px) {
            min-height: auto;
            padding-bottom: 10vh;
        }
    }

    .blurSliderLayerRight {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 95.76%);
        width: 350px;
        height: 700px;
        display: none;

        @media (max-width: 992px) {
            background: transparent;
        }

        @media (max-width: 677px) {
            display: none;
        }
    }

    .blurSliderLayerLeft {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: linear-gradient(270deg, #000000 -27.8%, rgba(0, 0, 0, 0.49) 107.63%);
        width: 350px;
        height: 700px;
        display: none;

        @media (max-width: 992px) {
            background: transparent;
        }

        @media (max-width: 677px) {
            display: none;
        }
    }

    .fadeRight {
        .blurSliderLayerRight {
            display: block;

            @media (max-width: 992px) {
                display: block;

            }

            @media (max-width: 677px) {
                display: none;
            }
        }
    }

    .fadeLeft {
        .blurSliderLayerLeft {
            display: block;


            @media (max-width: 992px) {
                display: block;

            }

            @media (max-width: 677px) {
                display: none;
            }
        }
    }

    .phoneSlideSize {
        @media (max-width: 992px) {
            width: 250px;
        }
    }

    .sideItem3Pos {
        position: absolute;
        top: 10%;
        left: 0;
        z-index: 0;

        img {
            @media (max-width: 992px) {
                // font-size: 30px;
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .sideItem4Pos {
        position: absolute;
        bottom: 0%;
        left: 0;

        img {
            @media (max-width: 992px) {
                width: 250px;
            }

            @media (max-width: 677px) {
                width: 150px;
            }
        }
    }

    .slideDescDiv {
        min-height: 10vh;

        @media (max-width: 677px) {
            min-height: 15vh;
        }
    }
}